import React from "react";

export default function FreeConsult() {
  return (
    <div>
      <script src="https://static.airtable.com/js/embed/embed_snippet_v1.js"/>
      <iframe
        className="airtable-embed airtable-dynamic-height"
        src="https://airtable.com/embed/shrBrY6XgWao7p62m?backgroundColor=orange"
        frameBorder="0"
        width="100%"
        height="1767"
        style={{background: "transparent", border: "1px solid #ccc"}}
        title="Get a free advertising consult"
      />
    </div>
  );
}
